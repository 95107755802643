<template>
	<v-container grid-list-xs fluid>
		<v-row>
			<v-col cols="12" sm="6" lg="4" v-for="menu in navigationMenus" :key="menu.name" my-2>
				<v-btn color="#FFFFFF" x-large block :to="{ name: menu.routeName }" height="80px">
					<v-icon class="menu-icon">{{ getIcon(menu.routeName) }}</v-icon>
					<v-flex xs12 class="text-xs-left">
						<span>{{ menu.name }}</span>
					</v-flex>
					<v-icon right color="grey" class="right">arrow_forward_ios</v-icon>
				</v-btn>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
	name: "OrganisationProfile",

	data: function() {
		return {
			navigationMenus: [
				{ name: "Organisation Details", routeName: "organisation-details" },
				{ name: "Contact Details", routeName: "organisation-contact-details" },
				{ name: "Staff", routeName: "organisation-users" },
				{ name: "Addresses", routeName: "organisation-addresses" },
				{ name: "Accounts", routeName: "organisation-accounts" },
				{ name: "Connections", routeName: "organisation-connections" },
				{ name: "Settings", routeName: "organisation-settings" },
				{ name: "Devices", routeName: "organisation-devices" },
				{ name: "Active Orders", routeName: "organisation-active-orders" },
				{ name: "Transaction History", routeName: "organisation-transaction-history" },
				{ name: "Statements", routeName: "organisation-statements" }
			]
		};
	},

	methods: {
		getIcon(name) {
			let icon = "";
			switch (name) {
				case "organisation-details":
					icon = "store_mall_directory";
					break;
				case "organisation-contact-details":
					icon = "mdi-contact-phone";
					break;
				case "organisation-users":
					icon = "people";
					break;
				case "organisation-addresses":
					icon = "mdi-map-marker";
					break;
				case "organisation-accounts":
					icon = "mdi-wallet";
					break;
				case "organisation-connections":
					icon = "mdi-transit-connection-variant";
					break;
				case "organisation-settings":
					icon = "mdi-cog-outline";
					break;
				case "organisation-devices":
					icon = "devices";
					break;
				case "organisation-active-orders":
					icon = "shopping_basket";
					break;
				case "organisation-transaction-history":
					icon = "account_balance_wallet";
					break;
				case "organisation-statements":
					icon = "mdi-list-status";
					break;
			}
			return icon;
		}
	}
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
::v-deep .v-btn__content {
	width: 100%;
}

.v-btn > .v-btn__content .v-icon.menu-icon {
	color: #999;
	margin-right: 15px;
	font-size: 30px;
}
</style>
